<template>
  <div>
    <el-row style="position: center">
      <h2>发布文章</h2>
      <el-form ref="form" :model="form" label-width="60px">
        <el-form-item label="标题">
          <el-input v-model="form.title" style="width: 70%; padding-right: 2px" />
        </el-form-item>
        <el-form-item label="摘要" style="width: 70%; padding-right: 2px">
          <el-input v-model="form.excerpt" type="textarea" autosize style="padding-right: 1px;" />
        </el-form-item>
        <el-form-item label="内容">
          <rich-text style="width: 80%; padding-right: 2px" @content="processContent" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">发布</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import RichText from '@/components/RichText'
import { submitArticle } from '@/api/article'

export default {
  name: 'PublishArticle',
  components: { RichText },
  data() {
    return {
      content: '',
      form: {
        title: '',
        excerpt: '',
        content: ''
      }
    }
  },
  created() {
  },
  methods: {
    processContent(value) {
      this.form.content = value
    },
    onSubmit() {
      console.log(this.form)
      submitArticle(this.form).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '投稿成功',
            type: 'warning',
            duration: 3000
          })
          this.$router.push('/post/list/article')
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
</style>
